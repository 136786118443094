<template>
    <v-container background="white" class="convert-credits-page">
        <v-card class="pa-4 mt-4">
            <v-row class="justify-space-between text-center">
                <v-col cols="12">
                    <h3>PressCable is now closed and part of AmpiFire</h3>
                    <h2 class="pt-4">Upgrade Your PressCable Credits</h2>
                    <div
                        v-if="discountStarted && !discountExpired"
                        class="pa-4"
                    >
                        <p class="pt-4 mb-0 font-weight-bold">
                            <span class="text-primary">20% discount</span>
                            expires in:
                        </p>
                        <a-countdown-timer :end="discountExpiry" />
                    </div>
                    <div v-else>
                        <p class="pt-4 font-weight-bold text-primary">
                            Discount period expired
                        </p>
                    </div>
                    <p class="pt-2">
                        PressCable is now part of AmpiFire, a more powerful
                        content distribution platform.
                    </p>
                    <p>
                        AmpiFire gets you greater exposure and traffic. Your
                        existing PressCable credits still let you publish news
                        articles to major brands, but with AmpiFire, you can do
                        much more.
                    </p>
                    <p v-if="discountStarted && !discountExpired">
                        Upgrade your PressCable distribution credits at a
                        <span class="font-weight-bold text-primary">
                            20% discount
                        </span>
                        before prices increase.
                    </p>
                    <p v-else>Upgrade your PressCable distribution credits.</p>
                </v-col>
                <v-col cols="12">
                    <h3>Here's what you'll get:</h3>
                    <p>
                        <b>More Multimedia Content:</b>
                        Generate content in 5+ formats – news articles, blog
                        posts, videos, audio ads, infographics, and slideshows.
                    </p>
                    <v-card flat class="align-center justify-center">
                        <v-img
                            max-height="400"
                            contain
                            :src="
                                require('@/assets/img/ampifire-distribution-png.png')
                            "
                        />
                        <v-card-title class="text-subtitle-2">
                            <span class="d-flex mx-auto text-center">
                                Amps create 5 more versions of your content and
                                <br />
                                publish to even more sites
                            </span>
                        </v-card-title>
                    </v-card>
                    <p>
                        <b>500% More Rankable Content:</b>
                        Get +5 rankings on Google's front page with a single Amp
                        campaign, compared to just 1 with PressCable.
                    </p>
                    <p>
                        <b>Greater Authority:</b>
                        Publish on 100+ new sites across 6 formats, including
                        YouTube, Google, Apple, Pinterest, and more, boosting
                        traffic and sales.
                    </p>
                    <p>
                        <b>NEW Traffic & Profit Training ($4995 Value):</b>
                        Access updated AmpiFire training, optimizing traffic,
                        sales, and the option to resell Amps for
                        $1,000-$2,000/mo.
                    </p>
                    <h2 v-if="discountStarted && !discountExpired">
                        The
                        <span class="text-primary">20% discount</span>
                        offer expires soon.
                    </h2>
                    <p>
                        Upgrade your credits and generate more traffic and sales
                        with AmpiFire.
                    </p>
                </v-col>
            </v-row>
            <v-row class="mt-4 ma-0">
                <v-col cols="12" class="pa-0">
                    <v-card flat max-width="400" class="mx-auto">
                        <a-text-input
                            v-model="creditsToUpgrade"
                            label="How many credits do you want to convert?"
                            type="number"
                            min="1"
                            :max="maxCredits"
                            :hint="availableCreditsHintMessage"
                            :rules="`between:1,${maxCredits ? maxCredits : 1}`"
                            :disabled="isSaving"
                            :loading="isSaving"
                            block
                        />
                        <v-btn
                            class="mr-4 mb-4"
                            :disabled="creditsToUpgrade < 1 || maxCredits <= 0"
                            :loading="isSaving"
                            block
                            @click="convert(creditsToUpgrade)"
                        >
                            Convert {{ creditsToUpgrade }} Credit(s)
                        </v-btn>
                        <v-btn
                            v-if="priceForAllCredits"
                            color="primary"
                            :disabled="creditsToUpgrade < 1"
                            :loading="isSaving"
                            block
                            @click="convert(maxCredits)"
                        >
                            Convert All Credits For ${{ priceForAllCredits }}
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { Endpoint } from '@/mixins';
import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { ACountdownTimer } from '@/components/ACountdownTimer';

@Component({
    components: {
        AForm,
        ATextInput,
        ACountdownTimer
    }
})
export default class Convert extends mixins(Endpoint) {
    isSaving = false;
    endpoint = '/credits/upgrade_presscable';

    maxCredits = 0;
    creditsToUpgrade = 1;
    priceForAllCredits = 0;

    discountStart = 1692590400;
    discountStarted = Math.floor(Date.now() / 1000) > this.discountStart;

    discountExpiry = 1693551600;
    discountExpired = Math.floor(Date.now() / 1000) > this.discountExpiry;

    onMounted() {
        this.getData();
    }

    get availableCreditsHintMessage() {
        return `Available PressCable distribution credits: ${this.maxCredits}`;
    }

    getData() {
        return this.$http
            .get(this.endpoint)
            .then(({ data }) => {
                this.maxCredits = data.data.pressCableDistributionCredits;
                this.creditsToUpgrade = data.data.pressCableDistributionCredits;
                this.priceForAllCredits = data.data.priceForAllCredits;
                if (this.maxCredits === 0) {
                    this.$store.dispatch(
                        'notification/error',
                        'You have no credits to upgrade!'
                    );
                    return this.$router.push(`/profile/credits`);
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    handleSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            window.location.href = redirect;
        }
    }

    convert(amountOfCredits: number) {
        this.isSaving = true;
        this.$http
            .post(this.endpoint, {
                upgrade_presscable_credits: amountOfCredits
            })
            .then(({ data }) => {
                if (data.data.success) {
                    this.handleSuccess(data.data);
                } else {
                    this.$store.dispatch('notification/error', data.error);
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(() => {
                this.isSaving = false;
            });
    }
}
</script>
<style lang="scss" scoped>
.convert-credits-page {
    font-size: 1.1em;
}
.distribution-subtitle-text {
    word-break: break-all;
}
.text-primary {
    color: $primary-color;
}
</style>
